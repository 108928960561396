<div class="row">
	<div class="col-12">
		<div class="card">
			<div class="card-body">
				<span
					class="text-secondary font-weight-bold"
					i18n="@@contactInfoTitle"
					>Here is how you can contact us</span
				>
			</div>
			<ul class="list-group list-group-flush">
				<li class="list-group-item">
					<div class="row justify-content-between">
						<div class="col-auto text-secondary">
							<span i18n="@@contactInfoEmailTitle">Email</span>
						</div>
						<div class="col-auto">
							<span i18n="@@contactInfoEmailValue"
								>info@boklisten.no</span
							>
						</div>
					</div>
				</li>
				<li class="list-group-item">
					<div class="row justify-content-between">
						<div class="col-auto text-secondary">
							<span i18n="@@contactInfoAddressTitle"
								>Address</span
							>
						</div>
						<div class="col-auto">
							<span i18n="@@contactInfoAddressValue"
								>Postboks 8, 1316 Eiksmarka</span
							>
						</div>
					</div>
				</li>
				<li class="list-group-item">
					<div class="row justify-content-between">
						<div class="col-auto text-secondary">
							<span i18n="@@contactInfoPhoneTitle">Phone</span>
						</div>
						<div class="col-auto">
							<span i18n="@@contactInfoPhoneValue">91002211</span>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</div>
