<div class="row">
	<div class="col-12">
		<ul class="nav nav-pills nav-fill">
			<li
				class="nav-item text-secondary bl-pointer"
				[ngClass]="{ 'text-info': deliveryMethod === 'branch' }"
				(click)="onSetDelivery('branch')"
				*ngIf="branchOption"
			>
				<div>
					<fa-icon
						[icon]="'store'"
						*ngIf="!(wait && deliveryMethod === 'branch')"
						size="2x"
						class="mr-2"
					></fa-icon>
					<fa-icon
						[icon]="'circle-notch'"
						[spin]="true"
						size="2x"
						*ngIf="wait && deliveryMethod === 'branch'"
					></fa-icon>
				</div>
				<span
					class="nav-link text-center"
					[ngClass]="{
						'bl-nav-btn-active': deliveryMethod === 'branch'
					}"
				>
					<span
						i18n="@@cartDeliveryOptionBranchButton"
						class="text-center"
						>At branch</span
					>
				</span>
			</li>

			<li
				class="nav-item text-secondary bl-pointer"
				[ngClass]="{ 'text-info': deliveryMethod === 'bring' }"
				(click)="onSetDelivery('bring')"
				*ngIf="mailOption"
			>
				<div>
					<fa-icon
						[icon]="'truck'"
						*ngIf="!(wait && deliveryMethod === 'bring')"
						size="2x"
						class="mr-2"
					></fa-icon>
					<fa-icon
						[icon]="'circle-notch'"
						[spin]="true"
						size="2x"
						*ngIf="wait && deliveryMethod === 'bring'"
					></fa-icon>
				</div>
				<span
					class="nav-link text-center"
					[ngClass]="{
						'bl-nav-btn-active': deliveryMethod === 'bring'
					}"
					i18n="@@cartDeliveryOptionBringButton"
					>By mail</span
				>
			</li>
		</ul>
	</div>
</div>

<div class="row">
	<div
		class="col-12 mt-4"
		*ngIf="bringInputWarning && deliveryMethod === 'bring'"
	>
		<div class="alert alert-warning">
			<fa-icon [icon]="'exclamation-triangle'" class="mr-2"></fa-icon>
			<span
				*ngIf="bringInputWarning === 'invalid-name'"
				i18n="@@cartDeliveryOptionBranchInvalidName"
				>please provide a valid name</span
			>
			<span
				*ngIf="bringInputWarning === 'invalid-address'"
				i18n="@@cartDeliveryOptionBranchInvalidAddress"
				>please provide a valid address</span
			>
			<span
				*ngIf="bringInputWarning === 'invalid-postal-code'"
				i18n="@@cartDeliveryOptionBranchInvalidPostalCode"
				>please provide a valid postal code</span
			>
		</div>
	</div>

	<div class="col-12 mt-4 text-center" *ngIf="deliveryMethod === 'branch'">
		<span i18n="@@cartDeliveryOptionBranchDescription"
			>Retrieve the items yourself at</span
		>
		<h3>{{ branch.name }}</h3>
	</div>

	<div class="col-12 mt-4" *ngIf="deliveryMethod === 'bring'">
		<div class="row">
			<div class="col-12">
				<h5 i18n="@@cartDeliveryShipmentAddressTitle">
					Shipment address
				</h5>
			</div>
			<div class="col-12">
				<div class="input-group mb-3 input-group-md">
					<div class="input-group-prepend">
						<span class="input-group-text">
							<fa-icon [icon]="'user'"></fa-icon>
						</span>
					</div>
					<input
						type="text"
						required
						class="form-control"
						i18n-placeholder="@@cartDeliveryShipmentNamePlaceholder"
						placeholder="Full Name"
						(change)="validateAndSave()"
						(keyup)="validateAndSave()"
						(keyup.enter)="validateAndSave()"
						[(ngModel)]="toName"
					/>
				</div>
			</div>

			<div class="col-12">
				<div class="input-group mb-3 input-group-md">
					<div class="input-group-prepend">
						<span class="input-group-text">
							<fa-icon [icon]="'address-card'"></fa-icon>
						</span>
					</div>

					<input
						type="text"
						required
						class="form-control"
						i18n-placeholder="
							@@cartDeliveryShipmentAddressPlaceholder"
						placeholder="Address"
						(change)="validateAndSave()"
						(keyup)="validateAndSave()"
						(keyup.enter)="validateAndSave()"
						[(ngModel)]="toAddress"
					/>
				</div>
			</div>

			<div class="col-12">
				<div class="input-group mb-3 input-group-md">
					<div class="input-group-prepend">
						<span class="input-group-text">
							<fa-icon [icon]="'address-card'"></fa-icon>
						</span>
					</div>

					<input
						type="text"
						required
						maxlength="4"
						class="form-control"
						(change)="validateAndSave()"
						(keyup)="validateAndSave()"
						(keyup.enter)="validateAndSave()"
						i18n-placeholder="
							@@cartDeliveryShipmentPostalCodePlaceholder"
						placeholder="Postal code"
						[(ngModel)]="toPostalCode"
					/>

					<input
						type="text"
						readonly
						required
						disabled
						class="form-control"
						i18n-placeholder="
							@@cartDeliveryShipmentPostalCityPlaceholder"
						placeholder="city"
						[(ngModel)]="toPostalCity"
					/>
				</div>
			</div>
		</div>

		<div class="row justify-content-between mt-2" *ngIf="currentDelivery">
			<div class="col-auto text-secondary">
				<span i18n="@@cardDeliveryInfoDeliveryCost">Delivery cost</span>
			</div>
			<div class="col-auto font-weight-bold">
				<fa-icon
					[icon]="'circle-notch'"
					[spin]="true"
					*ngIf="waitForPrice && deliveryMethod === 'bring'"
				></fa-icon>
				<span *ngIf="!waitForPrice">
					{{ currentDelivery.amount | blcPrice }}
				</span>
			</div>
		</div>

		<div class="row justify-content-between" *ngIf="currentDelivery">
			<div class="col-auto text-secondary">
				<span>Pakketype</span>
			</div>
			<div class="col-auto">
				<fa-icon
					[icon]="'circle-notch'"
					[spin]="true"
					*ngIf="waitForPrice && deliveryMethod === 'bring'"
				></fa-icon>
				<span *ngIf="!waitForPrice">
					{{ product }}
				</span>
			</div>
		</div>

		<div
			class="row justify-content-between"
			*ngIf="deliveryMethod === 'bring' && currentDelivery"
		>
			<div class="col-auto text-secondary">
				<span i18n="@@cartDeliveryEstimatedDeliveryDate"
					>Estimated delivery date</span
				>
			</div>
			<div class="col-auto">
				<div class="row justify-content-end">
					<div class="col-auto">
						<fa-icon
							[icon]="'circle-notch'"
							[spin]="true"
							*ngIf="waitForPrice && deliveryMethod === 'bring'"
						></fa-icon>
						<span *ngIf="!waitForPrice">
							{{
								currentDelivery.info["estimatedDelivery"]
									| blcDate: "until"
							}}
						</span>
					</div>
				</div>
				<div class="row justify-content-end">
					<div class="col-auto text-secondary">
						<fa-icon
							[icon]="'circle-notch'"
							[spin]="true"
							*ngIf="waitForPrice && deliveryMethod === 'bring'"
						></fa-icon>
						<small *ngIf="!waitForPrice">{{
							currentDelivery.info["estimatedDelivery"] | blcDate
						}}</small>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-12 mt-4" *ngIf="!wait && !currentDelivery">
		<div class="alert alert-warning">
			<span i18n="@@cartDeliveryInfoFailure"
				>Delivery info could not be processed. Are you sure that the
				info is correct? Is the postal code valid?</span
			>
		</div>
	</div>

	<div class="col-12 mt-4">
		<button
			class="btn btn-outline-success btn-block"
			(click)="onConfirmDelivery()"
			[disabled]="wait || bringInputWarning.length > 0"
		>
			<span i18n="@@cartDeliveryConfirmButton">Confirm</span>
		</button>
	</div>
</div>
