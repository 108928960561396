<app-blc-spinner [loading]="wait" *ngIf="wait"></app-blc-spinner>
<div *ngIf="view && item" class="row align-items-center">
	<div class="col-12">
		<div class="card">
			<div class="card-header bg-info text-white">
				<div class="row justify-content-between align-items-center">
					<div class="d-flex text-center flex-nowrap ml-4 bl-text-sm">
						<a routerLink="{{ '/i/' + item.id }}">{{
							item.title
						}}</a>
					</div>
					<div class="text-white font-weight-bold bl-text-sm">
						<div class="mr-3">
							<app-item-add
								*ngIf="showAdd"
								[item]="item"
								[type]="orderItemAction.action"
								[branchItem]="branchItem"
								[period]="orderItemAction.period"
								[autoAdd]="autoAdd"
							></app-item-add>
						</div>
					</div>
				</div>
			</div>
			<div
				class="card-body bg-secondary text-white"
				*ngIf="isCustomerItem()"
			>
				<div class="row">
					<div
						class="col-auto"
						i18n="
							displayed when the item in cart is your item
							@@itemDisplayYourItem"
					>
						Your item
					</div>
				</div>
			</div>

			<div class="card-body">
				<div class="d-flex justify-content-between align-items-center">
					<div
						*ngIf="
							isCustomerItem() ||
							(!alreadyOrdered && !alreadyHaveItem)
						"
						class="ml-2 w-5 w-50"
					>
						<span
							class="text-large text-dark font-weight-bold"
							*ngIf="!customerItem"
							>{{ getPrice() | blcPrice }}</span
						>
						<span
							class="ml-2 text-secondary bl-text-sm font-italic"
							*ngIf="
								orderItemAction &&
								orderItemAction.action === 'partly-payment' &&
								getPrice() !== 0
							"
						>
							<span
								i18n="@@itemDisplayPartlyPaymentAmountLeftToPay"
								>pay later</span
							>:
							<span>{{ getAmountLeftToPay() | blcPrice }}</span>
						</span>

						<span *ngIf="customerItem && orderItemAction">{{
							customerItem
								| blcCustomerItemPrice: orderItemAction.action
								| async
								| blcPrice
						}}</span>
					</div>
					<div
						*ngIf="
							isCustomerItem() ||
							(!alreadyOrdered && !alreadyHaveItem)
						"
					>
						<app-item-type-select
							[branchItem]="branchItem"
							[item]="item"
							[customerItem]="customerItem"
							[type]="orderItemType"
							(typeChange)="onOrderItemActionChange($event)"
						></app-item-type-select>
					</div>
				</div>
			</div>

			<div
				class="card-footer"
				*ngIf="!isCustomerItem() && (alreadyHaveItem || alreadyOrdered)"
			>
				<div class="row justify-content-center">
					<div class="col-auto text-secondary">
						<span
							*ngIf="alreadyHaveItem"
							i18n="@@itemDisplayAlreadyHaveThisItem"
							>You already have this item</span
						>
						<span
							*ngIf="alreadyOrdered && !alreadyHaveItem"
							i18n="@@itemDisplayAlreadyOrderedItem"
							>You have already ordered this item</span
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
