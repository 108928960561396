import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-fastbuy",
	templateUrl: "./fastbuy.component.html",
	styleUrls: ["./fastbuy.component.scss"],
})
export class FastbuyComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
