import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-info-corona",
	templateUrl: "./info-corona.component.html",
	styleUrls: ["./info-corona.component.scss"],
})
export class InfoCoronaComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
