import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-info-for-pupils",
	templateUrl: "./info-for-pupils.component.html",
	styleUrls: ["./info-for-pupils.component.scss"],
})
export class InfoForPupilsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
