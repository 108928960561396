<div class="row justify-content-center mt-2 mb-2" *ngIf="waitingForDibs">
	<div class="col-auto text-secondary">
		<fa-icon [icon]="'circle-notch'" [spin]="true"></fa-icon>
		<span class="ml-2" i18n="@@cartPaymentRetrievingInfoFromDibs"
			>retrieving info from DIBS, please wait.</span
		>
	</div>
</div>

<div id="bl-dibs-wrapper"></div>
