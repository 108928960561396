<div class="row">
	<div class="col">
		<h4>{{ forCompaniesTitle }}</h4>
	</div>
</div>
<div class="row">
	<div class="col">
		<p>{{ forCompaniesDesc }}</p>
	</div>
</div>
<div class="row">
	<div class="col-12">
		<h5>{{ forCompaniesList.title }}</h5>
	</div>
	<div class="col-12">
		<ul>
			<li *ngFor="let listItem of forCompaniesList.listItems">
				{{ listItem }}
			</li>
		</ul>
	</div>
</div>

<div class="row mt-5" *ngFor="let forCompaniesText of forCompaniesTexts">
	<div class="col-12">
		<h5>{{ forCompaniesText.title }}</h5>
	</div>
	<div class="col-12">
		<p *ngFor="let textBlock of forCompaniesText.textBlocks">
			{{ textBlock }}
		</p>
	</div>
</div>

<div class="row mt-5">
	<div class="col-12">
		<div class="alert alert-secondary">
			Listen er for øyeblikket ikke tilgjengelig, men vil dukke opp utover
			høsten!
		</div>
	</div>
</div>
