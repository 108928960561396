<div class="row bg-white">
	<div class="col-12">
		<div class="row">
			<div class="col-12">
				<span class="text-secondary bl-text-sm mb-2"
					>Sist oppdatert: 01.07.18</span
				>
				<h1>Vilkår</h1>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col-12">
				<h5>Priser</h5>
				<p>
					Alle priser er i NOK og inkludert MVA bortsett fra på bøker
					som ikke er MVA pliktige.
				</p>

				<h5>Forbehold</h5>
				<p>
					Vi tar forbehold om eventuelle trykkfeil når det gjelder
					pris og informasjon om varer/tjenestene på siden.
				</p>

				<h5>Hjelp</h5>
				<p>
					Ta kontakt med oss hvis du har spørsmål. Vi ønsker å gi deg
					god hjelp og en vellykket handel!
				</p>

				<h5>Angrerett og reklamasjon</h5>
				<p>
					Vi har 14 dagers angrerett fra du får varen - uansett om du
					bestiller på nett eller kjøper i en av våre skolebokutsalg.
				</p>

				<h5>Avbestilling</h5>
				<p>
					Du kan alltid avbestille ordre som ikke er blitt utlevert
					eller sendt. Ta kontakt med oss.
				</p>

				<h5>Leveranse</h5>
				<p>
					Bøkene du bestiller kan du hente selv på en av våre stands,
					eller få tilsendt i posten. Ved utsendelse i posten kan det
					påløpe et transportgebyr. Ved bestilling velger du selv
					måten du får bøkene på. Har du bestilt henting på stand
					henter du bøkene på stand, har du bestilt sending i posten
					kommer de automatisk hjem til deg.
				</p>

				<h5>Kortinformasjon</h5>
				<p>
					Når du handler hos Boklisten.no AS blir betalingen behandlet
					av DIBS, som er en sikker elektronisk betalingsløsning for
					VISA og MasterCard. All kortinformasjon blir oppbevart i
					henhold til kortselskapenes regelverk.
				</p>

				<h5>Endringer</h5>
				<p>
					I visse tilfeller vil vi måtte endre våre betingelser.
					Dersom det gjøres større endringer, vil vi informere om
					dette på nettsiden, og eventuelt på e-post. Dersom du har
					kjøpt eller lånt bøker fra oss vil det uansett være
					betingelsene på avtaleinngåelsestidspunktet som er
					gjeldende.
				</p>

				<h5>Personopplysninger</h5>
				<p>
					Første gang du registrerer deg og bestiller, må du oppgi
					kontaktinformasjonen din. Dette inkluderer navn,
					telefonnummer, fødselsdato, adresse og skolen du går på. Vi
					vil også kunne samle inn informasjon om hvordan du bruker
					nettsiden vår – hva du bestiller, når du bestiller, på hva
					slags type enhet du bestiller, om du gjennomfører
					bestilling.
				</p>
				<p>
					Boklisten.no AS behandler persondata ifølge «Lov om
					behandling av personopplysninger» av 20.07.2018.
					Opplysninger som kan knyttes til deg som person vil aldri
					bli gjort tilgjengelig for andre virksomheter eller koblet
					med andre eksterne register, med unntak av dersom det er
					nødvendig for at du skal oppfylle dine kontraktsmessige
					forpliktelser. Et eksempel kan være eksternt faktura- eller
					inkassoselskap. For fullstendig informasjon om hvordan vi
					registrerer og bruker dine personopplysninger, se vår
					personvernavtale.
				</p>
			</div>
		</div>
	</div>
</div>
