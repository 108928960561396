<div class="container">
	<div class="m-2"></div>
	<app-cart-empty *ngIf="cartSize <= 0 || !cartSize"></app-cart-empty>

	<div class="row" *ngIf="cartSize > 0">
		<div class="col-12">
			<div class="row justify-content-center">
				<div class="col-auto text-secondary">
					<fa-icon [icon]="'shopping-cart'" [size]="'2x'"></fa-icon>
				</div>
			</div>
			<div class="row justify-content-center mt-2">
				<div class="col-auto justify-content-center text-secondary">
					<h4 i18n="@@cartTitle">Cart</h4>
				</div>
			</div>
		</div>
	</div>

	<div class="row mt-2 justify-content-center" *ngIf="cartSize > 0">
		<div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
			<div class="row justify-content-center">
				<div class="col-12 mb-1" *ngFor="let cartItem of getCart()">
					<app-item-display
						[compact]="true"
						[inCart]="true"
						[item]="cartItem.item"
						[customerItem]="cartItem.customerItem"
						[branchItem]="cartItem.branchItem"
						[branch]="cartItem.branch"
					>
					</app-item-display>
				</div>
			</div>

			<div class="col-auto mt-5" *ngIf="showPartlyPaymentInfo">
				<h2 i18n="@@cartPartlyPaymentInfoTitle">
					About partly payments
				</h2>
				<p>
					Du betaler restbeløpet på det oppgitte tidspunktet.
					Restbeløpet betales ved vår bokinnkjøpsstand på din skole på
					slutten av semesteret eller på nett. Mange privatister
					ønsker å selge bøkene sine på slutten av semesteret og
					Boklisten kjøper inn bøker fra privatister.
				</p>
				<p>
					Hvis du selger boken din til Boklisten vil vi vanligvis
					betale det samme som restbeløpet eller mer.
				</p>
			</div>
		</div>
	</div>
</div>

<div class="bl-goto-footer" *ngIf="cartSize > 0">
	<div
		*ngIf="!isUserDetailValid"
		class="alert alert-warning text-center nav-link"
	>
		<fa-icon [icon]="'exclamation-triangle'" class="mr-2"></fa-icon>
		Du må fylle inn detaljer om deg selv før du kan bestille.
		<a class="bl-link font-weight-bold" (click)="navigateToUserDetails()"
			>Trykk her for å gå til brukerinnstillinger.</a
		>
	</div>
	<div class="row justify-content-center">
		<div class="col-sm-12 col-md-4">
			<button
				class="btn btn-success btn-block"
				routerLink="checkout"
				[disabled]="!isUserDetailValid"
			>
				<fa-icon icon="flag-checkered" class="mr-2"></fa-icon>
				<span i18n="@@cartCheckoutButton">Checkout</span>
			</button>
		</div>
	</div>
</div>
