import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-info-agreement-terms",
	templateUrl: "./info-agreement-terms.component.html",
	styleUrls: ["./info-agreement-terms.component.scss"],
})
export class InfoAgreementTermsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
