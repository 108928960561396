import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-cart-empty",
	templateUrl: "./cart-empty.component.html",
	styleUrls: ["./cart-empty.component.scss"],
})
export class CartEmptyComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
