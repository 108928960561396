<div class="row mb-5">
	<div class="col-12">
		<div class="card" *ngIf="order">
			<div class="card-header">
				<div class="row justify-content-between">
					<div class="col-auto mt-2">
						<button
							class="btn btn-outline-secondary btn-block btn-sm"
							(click)="getReceipt()"
						>
							<span i18n="@@orderDetailPrintReceiptButton"
								>Skriv ut kvittering</span
							>
						</button>
					</div>
					<div class="col-auto mt-2" *ngIf="showAgreementPrintOut">
						<button
							class="btn btn-outline-secondary btn-block btn-sm"
							(click)="getAgreement()"
						>
							<span i18n="@@orderDetailPrintAgreementButton"
								>Skriv ut kontrakt</span
							>
						</button>
					</div>
				</div>
				<div class="row justify-content-center mt-2 mb-2" *ngIf="wait">
					<div class="col-auto text-secondary">
						<fa-icon
							[icon]="'circle-notch'"
							[spin]="true"
							[size]="'2x'"
						></fa-icon>
					</div>
				</div>
				<div class="row" *ngIf="receiptUrl || agreementUrl">
					<div class="col-12">
						<ul class="list-group mb-2 mt-2">
							<li
								*ngIf="receiptUrl"
								class="list-group-item d-flex justify-content-between align-items-center"
								(click)="openReceiptUrl()"
								style="cursor: pointer"
							>
								<span>
									<h5>Din kvittering</h5>
									<p>
										Kvitteringen er klar, klikk her for å
										åpne. (Ad-blocker må være avslått)
									</p>
								</span>
								<span>
									<fa-icon
										[icon]="'external-link-alt'"
										[size]="'2x'"
									></fa-icon>
								</span>
							</li>
							<li
								*ngIf="agreementUrl"
								class="list-group-item d-flex justify-content-between align-items-center"
								(click)="openAgreementUrl()"
								style="cursor: pointer"
							>
								<span>
									<h5>Din Låneavtale</h5>
									<p>
										Låneavtalen er klar, klikk her for å
										åpne. (Ad-blocker må være avslått)
									</p>
								</span>
								<span>
									<fa-icon
										[icon]="'external-link-alt'"
										[size]="'2x'"
									></fa-icon>
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="card-body">
				<!-- TODO: remove '&& false' to enable -->
				<div
					class="card-body bg-warning"
					*ngIf="order.pendingSignature && false"
				>
					<div class="row">
						<div class="col-auto">
							Du trenger signatur fra din foresatte før utdeling,
							be dem sjekke e-post
						</div>
					</div>
				</div>
				<ul class="list-group">
					<li
						class="list-group-item"
						[ngClass]="{
							'bl-order-item-moved': orderItem.movedToOrder
						}"
						*ngFor="let orderItem of order.orderItems"
					>
						<app-order-item-info
							[orderItem]="orderItem"
						></app-order-item-info>
					</li>
				</ul>

				<div class="row mt-4 justify-content-between">
					<div class="col-lg-6 col-md-6 col-sm-12 col-12">
						<app-order-delivery-info
							[order]="order"
							*ngIf="showDelivery"
						></app-order-delivery-info>
					</div>
					<div
						class="col-lg-6 col-md-6 col-sm-12 col-12 mt-md-0 mt-sm-4 mt-4"
					>
						<app-order-payment-info
							[order]="order"
						></app-order-payment-info>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="row justify-content-between align-items-center">
					<div class="col-auto text-secondary">
						<fa-icon [icon]="'clock'" class="mr-2"></fa-icon>
						{{ order.creationTime | blcDate: "timestamp" }}
					</div>
					<div class="col-auto text-secondary font-italic">
						<small>{{ order.id }}</small>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
