import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-info-agreement-conditions-old",
	templateUrl: "./info-agreement-conditions-old.component.html",
	styleUrls: ["./info-agreement-conditions-old.component.scss"],
})
export class InfoAgreementConditionsOldComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
