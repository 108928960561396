<h3>Informasjon vedrørende Covid-19</h3>
<br />
<p><strong>Innkjøpsliste</strong></p>
<p>
	Vi kjøper kun inn bøker som står på innkjøpslisten. Sjekk gjerne listen før
	du drar, så slipper du å dra på stand med bøker vi ikke tar imot. Du kan
	finne innkjøpslisten her:
	<a href="https://www.boklisten.no/info/buyback">
		https://www.boklisten.no/info/buyback
	</a>
</p>
<p><strong>Sende bøker i posten</strong></p>
<p>
	Tilbakekjøp av bøker kan også skje hvis du sender dem til:
	<strong>Boklisten.no AS, Postboks 8, 1316 Eiksmarka</strong>. Husk å legge
	ved et ark hvor du skriver ditt navn, telefonnummer, og hvilke bøker du
	selger til oss. Du bør også sende en pakke med sporing, og beholde sporingen
	inntil leveringen er registrert.
	<em>
		Vi kan ikke garantere at vi kjøper inn bøkene dine bare fordi du har
		sendt dem til oss.
	</em>
	Dersom du er usikker på om boken din kan kjøpes tilbake, viser vi til
	innkjøpslisten. Vi krever også at boken er i god nok stand til at vi kan
	bruke den igjen. Tror du boken din kanskje er i grenseland, kan du sende oss
	et bilde av den for godkjennelse før du sender boken.
</p>
<p><strong>Utsette betalingsfristen for andre avdrag</strong></p>
<p>
	Ettersom en del av dere har måttet utsette eksamen som følge av
	koronakrisen, har vi åpnet for å utsette fristen for å betale andre avdrag.
	Dette koster 50 kroner per bok, og endrer fristen for å betale andre avdrag
	fra 1.juli til 20.desember. Forlengelse kan gjøres på nettsiden, eller ved å
	overføre 50 kroner til vårt Vipps-nummer 33415 og sende en mail om hvilke
	bøker det gjelder til
	<a href="mailto:info@boklisten.no">info@boklisten.no</a>.
</p>
<p><strong>Betale andre avdrag</strong></p>
<p>
	Dersom du ønsker å beholde boken din, kan du betale andre avdrag nå. Dette
	kan du gjøre ved å logge deg inn på våre nettsider og betale der. Du kan
	også betale på stand, eller via Vipps-nummer 33415. Ønsker du å bruke Vipps,
	kan du sende oss en mail om dette. Andre avdrag er på 33% av bokens nypris,
	så lenge du betaler før fristen 1.juli. Sjekk «dine bøker» på nettsiden for
	nøyaktig sum.
</p>
