<div class="row">
	<div class="col">
		<ul class="list-group" *ngIf="!region">
			<li
				class="list-group-item list-group-item-secondary text-center"
				i18n="@@branchPickerChooseRegion"
			>
				Choose region
			</li>
			<li
				class="list-group-item list-group-item-action flex-fill align-items-center"
				*ngFor="let region of regions"
				(click)="selectRegion(region)"
			>
				{{ region }}
			</li>
		</ul>
		<ul class="list-group list-group-horizontal-md" *ngIf="region">
			<li
				class="list-group-item list-group-item-info list-group-item-action d-flex align-items-center"
				(click)="onChangeRegion()"
			>
				<fa-icon icon="chevron-left" class="mr-2"></fa-icon>
				{{ region }}
			</li>
			<li
				class="list-group-item list-group-item-info list-group-item-action flex-fill d-flex justify-content-between align-items-center"
				*ngIf="branch"
				(click)="onChangeBranch()"
			>
				<span>
					<div>{{ branch.name }}</div>
					<small>{{ branch.location.address }}</small>
				</span>
				<fa-icon icon="bars"></fa-icon>
			</li>
		</ul>
	</div>
</div>

<div class="row mt-2" *ngIf="region && !branch">
	<div class="col">
		<ul class="list-group">
			<li
				class="list-group-item list-group-item-warning text-center"
				*ngIf="wait"
			>
				<fa-icon icon="circle-notch" [spin]="true"></fa-icon>
			</li>
			<li
				class="list-group-item list-group-item-secondary text-center"
				i18n="@@branchPickerNoBranchesFound"
				*ngIf="!wait && branches.length <= 0"
			>
				No branches found
			</li>
			<li
				class="list-group-item list-group-item-secondary text-center"
				i18n="@@branchPickerChooseBranch"
				*ngIf="!wait && branches.length > 0"
			>
				Choose a branch
			</li>
			<li
				class="list-group-item list-group-item-action"
				*ngFor="let branch of branches"
				(click)="pick(branch)"
			>
				{{ branch.name }} - <small>{{ branch.location.address }}</small>
			</li>
		</ul>
	</div>
</div>
