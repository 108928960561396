<div class="row" *ngIf="!order">
	<div class="col-12">
		<div class="alert alert-secondary" i18n="@@cartSummaryError">
			Summary could not be provided
		</div>
	</div>
</div>

<div class="row justify-content-center">
	<div class="col-12">
		<table class="table table-sm">
			<thead>
				<tr>
					<th scope="col" i18n="@@cartSummaryItemTitle">Title</th>
					<th scope="col" i18n="@@cartSummaryItemAction">Action</th>
					<th scope="col" i18n="@@cartSummaryItemPrice">Price</th>
				</tr>
			</thead>
			<tbody *ngIf="order">
				<tr
					*ngFor="let orderItem of order.orderItems"
					class="font-weight-light"
				>
					<td>{{ orderItem.title }}</td>

					<td *ngIf="orderItem.type === 'rent'">
						<span i18n="@@cartSummaryRentTo">rent to</span>
						<span> {{ orderItem.info.to | blcDate: "date" }}</span>
					</td>

					<td *ngIf="orderItem.type === 'partly-payment'">
						<span i18n="@@cartSummaryPartlyPayment"
							>partly payment</span
						>
						<span> {{ orderItem.info.to | blcDate: "date" }}</span>
					</td>

					<td *ngIf="orderItem.type === 'extend'">
						<span i18n="@@cartSummaryExtendTo">extend to</span>
						<span> {{ orderItem.info.to | blcDate: "date" }}</span>
					</td>

					<td *ngIf="orderItem.type === 'buy'">
						<span i18n="@@cartSummaryBuy">buy</span>
					</td>

					<td *ngIf="orderItem.type === 'buyout'">
						<span i18n="@@cartSummaryBuyout">buyout</span>
					</td>

					<td>
						{{ orderItem.unitPrice | blcPrice: "international" }}
					</td>
				</tr>

				<tr *ngIf="delivery && delivery.method !== 'branch'">
					<td
						i18n="@@cartSummaryShipment"
						colspan="2"
						class="table-info"
					>
						Shipment
					</td>
					<td class="table-info">
						{{ delivery.amount | blcPrice: "international" }}
					</td>
				</tr>
				<tr *ngIf="payment">
					<th
						i18n="@@cartSummaryTotal"
						colspan="2"
						class="table-success"
					>
						Total
					</th>
					<th class="table-success">
						{{ payment.amount | blcPrice: "international" }}
					</th>
				</tr>

				<tr *ngIf="payment">
					<td
						colspan="2"
						i18n="@@cartSummaryTaxAmount"
						class="bl-text-sm"
					>
						Tax amount
					</td>
					<td class="bl-text-sm">
						{{ payment.taxAmount | blcPrice: "international" }}
					</td>
				</tr>

				<tr *ngFor="let partlyPaymentTotal of partlyPaymentTotals">
					<td
						i18n="@@cartSummaryPartlyPaymentPayLater"
						colspan="2"
						class="table-ligth font-weight-light bl-text-sm"
					>
						Rest amount to pay before
						{{ partlyPaymentTotal.date | blcDate }}
					</td>
					<td class="table-light font-italic bl-text-sm">
						{{
							partlyPaymentTotal.total | blcPrice: "international"
						}}
					</td>
				</tr>
				<tr *ngIf="showPartlyPayments">
					<td
						colspan="2"
						class="table-warning font-weight-light bl-text-sm"
						i18n="@@cartSummaryTotalIncludingPartlyPayments"
					>
						Total amount including partly payments
					</td>
					<td class="table-warning bl-text-sm">
						<span>{{
							totalAmountIncludingPartlyPayments()
								| blcPrice: "international"
						}}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
