<div class="row justify-content-center align-items-center">
	<div class="col-auto text-secondary">
		<fa-icon [icon]="'shopping-cart'" size="2x"></fa-icon>
	</div>
</div>
<div class="row justify-content-center mt-2 text-secondary">
	<div class="col-auto">
		<h4 class="text-center" i18n="@@cartEmptyTitle">Cart is empty</h4>
	</div>
</div>
<div class="row justify-content-center align-items-center mt-5">
	<div class="col-auto">
		<button class="btn btn-info btn-block" routerLink="/fastbuy/regions">
			<fa-icon [icon]="'cart-plus'" class="mr-2"></fa-icon>
			<span i18n="@@cartEmptyRentSomeItemsButton"
				>rent or buy some items</span
			>
		</button>
	</div>
</div>
