<div class="row">
	<div class="col"><h2>Videregåendeelever</h2></div>
</div>
<!--
<div class="row">
	<div class="col">
		<p>
			Dersom du går på Wang eller Otto Treider og trenger bøker utenom
			hovedutdelingsdagene, kan du kontakte våre kontaktelever. Snakk med
			skolens resepsjon eller administrasjon for å finne
			kontaktinformasjonen til disse.
		</p>
	</div>
</div>
-->

<div class="row">
	<div class="col-12"><h4>Ullern</h4></div>
	<div class="col-12">
		<ul>
			<li>Pia Cecilie Harm - 40018631</li>
			<li>Mia Briseid-Bødtker - 94424433</li>
			<li>Maya Yakovleva - 46296977</li>
			<li>Caroline Revlem - 96623097</li>
			<li>Kristine Hagen - 91990050</li>
			<li>Felix Olsen Stenbakk - 47955571</li>
			<li>Ralph Nicolai Latorena - 41345550</li>
		</ul>
	</div>

	<div class="col-12"><h4>Wang Oslo</h4></div>
	<div class="col-12">
		<ul>
			<li>Celine Hesthagen - 46935818</li>
			<li>Pernille Norum - 93276752</li>
			<li>Kaja Vinjum - 47676992</li>
			<li>Helena Lien - 94898095</li>
			<li>Yrjar Vederhus - 94795108</li>
		</ul>
	</div>

	<div class="col-12"><h4>Wang Romerike</h4></div>
	<div class="col-12">
		<ul>
			<li>Julie Hammer - 97076088</li>
			<li>Marie Jakobsen - 91746248</li>
			<li>Ida Lockert Skjold - 94034990</li>
			<li>Elise Berg - 46869603</li>
			<li>Ida Fuglset - 47949552</li>
			<li>Andreas Giske - 91918789</li>
		</ul>
	</div>

	<div class="col-12"><h4>Otto Treider</h4></div>
	<div class="col-12">
		<ul>
			<li>Markus Carlsen - 91623729</li>
			<li>Petter Hanekamp - 97307976</li>
			<li>Lea Herland - 47610032</li>
		</ul>
	</div>
</div>

<div class="row mt-5">
	<div class="col-12"><h3>Utdeling og innsamling</h3></div>
	<div class="col-12">
		<p>
			Spesifiserte tidsplaner for hver klasse på utdelingsdagene sendes ut
			av skolene, og blir også hengt opp rundt skolen på disse dagene.
		</p>
		<p>
			Har du spørsmål? Sjekk innom siden for ofte stilte spørsmål, eller
			send oss en mail på info@boklisten.no
		</p>
	</div>
</div>
