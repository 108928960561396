<app-info-agreement-conditions></app-info-agreement-conditions>

<div class="row bg-white">
	<div class="col-12"><hr /></div>
</div>

<div class="row bg-white">
	<div class="col-12">
		<span class="text-secondary bl-text-sm mb-2"
			>Gjelder for ordre gjennomført mellom: 19.01.20 og 01.07.21</span
		>
		<h2>Boklistens betingelser for utlån og salg av bøker på avdrag</h2>
	</div>
	<div class="col-12">
		<p>
			Det avtales herved at Boklisten.no AS (Adr: Pb 8, 1359 Eiksmarka,
			tlf: 91002211, epost info@boklisten.no), heretter kalt Boklisten
			låner ut bøker eller selger bøker på avdrag til kunden.
		</p>
		<h4>Tidspunkt for tilbakelevering av lånte bøker</h4>
		<p>
			Utlånte bøker må leveres tilbake ved semesterets eller skoleårets
			slutt 20. desember eller 1. juli som avtalt når du låner boken. Det
			er ditt ansvar å levere tilbake boken og informasjon om
			tilbakelevering vil finnes på Boklistens nettsider. Ved å låne bøker
			godtar du at Boklisten sender deg påminnelser om tilbakelevering av
			bøker.
		</p>
		<h4>Kjøp av bøker på avdrag</h4>
		<p>
			Ved avdragskjøp avtales pris på begge avdrag ved kjøpstidspunktet,
			og første avdrag betales innen mottak av boken. Det avtales for hver
			enkelt bok om andre avdrag forfaller ved nærmeste 20.desember eller
			1.juli. Du kan komme til vår innkjøpsstand ved slutten av semesteret
			eller logge seg på nettsiden for å betale andre avdrag før
			betalingsfristen. Hvis dette ikke gjøres, vil andre avdrag
			faktureres, og det påløper et administrasjonsgebyr på 150 kr per
			bok.
		</p>
		<h4>Boklistens gjenbruksprogram</h4>
		<p>
			Boklisten ønsker å bidra til et bedre miljøet ved vår virksomhet.
			Dette gjør vi ved å gjøre det enkelt å gjenbruke bøker. For å kunne
			kjøpe bøker på avdrag godtar du å bli med i Boklistens
			gjenbruksprogram og du vil kunne motta generaliserte mailer om
			betalingsfrister, spørreundersøkelser, Boklistens innkjøpslister og
			-priser samt informasjon fra samarbeidspartnere. Dette samtykket kan
			når som helst trekkes tilbake og medlemskapet i vårt
			gjenbruksprogram utløper automatisk 1 år etter at siste avdrag er
			betalt.
		</p>
		<h4>Tilbakelevering av bøker du har lånt</h4>
		<p>
			Ved tilbakelevering av lånte bøker må de være i god stand. Boklisten
			har ensidig rett til å avgjøre om en bok aksepteres eller må
			erstattes. Det anbefales derfor å behandle bøkene pent og bruke
			bokbind. Se mer informasjon på nettsidene til Boklisten.no om hva
			som godtas ved tilbakelevering av bøker. Bøker som ikke leveres
			tilbake fra elever eller er ødelagte, mistes eller av annen grunn
			ikke leveres innen fristen må erstattes med forlagenes listepris +
			prisjustering på 10% + gebyr på 90 kr per bok.
		</p>
		<h4>Gjenbruk av bøker</h4>
		<p>
			Mange privatister ønsker å selge bøkene sine på slutten av
			semesteret og Boklisten kjøper inn bøker fra privatister. Hvis du
			selger boken din til Boklisten vil vi vanligvis betale det samme som
			andre avdrag eller mer, men vi kan dessverre ikke gi noen garanti
			for at vi kjøper inn akkurat din bok.
		</p>
		<h4>Betingelser for utlevering av bøker fra Boklisten</h4>
		<p>
			Boklisten forbeholder seg retten til å bestemme hvor og når bøker
			utleveres, men vil alltid tilby overlevering av dine bøker på minst
			en av følgende måter:
		</p>
		<ul>
			<li>På en stand organisert av Boklisten.</li>
			<li>Via sending i post fra Boklisten.</li>
			<li>
				Direkte fra en person som Boklisten bestemmer. Dette er aktuelt
				hvis noen andre er i besittelse av boken du skal motta og
				Boklisten vil bestemme et rimelig tidspunkt og sted for
				overlevering av boken. Ditt navn og telefonnummer vil bli gitt
				til den som skal gi deg boken.
			</li>
		</ul>
		<h4>
			Betingelser for mottak av bøker som Boklisten kjøper inn eller
			tilbakelevering av bøker som er lånt ut via/fra Boklisten
		</h4>
		<p>
			Boklisten forbeholder seg retten til å bestemme hvor og når bøker
			mottas, men vil alltid akseptere å motta dine bøker på minst en av
			følgende måter:
		</p>
		<ul>
			<li>På en stand organisert av Boklisten.</li>
			<li>Via sending i post fra Boklisten.</li>
			<li>
				Direkte til en person som Boklisten bestemmer. Dette er aktuelt
				hvis Boklisten allerede har en mottaker av din bok og Boklisten
				vil bestemme et rimelig tidspunkt og sted for overlevering av
				boken. Ditt navn og telefonnummer vil bli gitt til den som skal
				gi deg boken.
			</li>
		</ul>
		<h4>Norsk rett</h4>
		<p>
			Denne avtale reguleres av norsk rett. Eventuelle tvister med
			utspring i avtaleforholdet skal avgjøres ved behandling i de
			ordinære domstoler. Oslo tingrett skal være verneting.
		</p>
	</div>
</div>
