<app-header></app-header>
<!--<div class="bl-header-alert"><app-header-alert></app-header-alert></div>-->
<div class="bl-content" [ngClass]="{ gutter: showGutter }">
	<div class="bl-header-alert" *ngIf="showAlert">
		<app-header-alert></app-header-alert>
	</div>
	<router-outlet></router-outlet>

	<div class="bl-goto-footer" *ngIf="showCookieInfo">
		<div class="row justify-content-center">
			<div class="col-12 text-center">
				<p i18n="@@cookiesBannerText" i18n="@@cookiesAbout">
					Our pages uses cookies. This is done to ensure that you have
					the best possible experience.
				</p>
			</div>
			<div class="col-auto">
				<button
					class="btn btn-sm btn-outline-secondary mr-3"
					routerLink="/info/policies/privacy"
					i18n="@@cookiesReadMore"
				>
					Read more
				</button>
				<button
					class="btn btn-secondary"
					(click)="acceptCookies()"
					i18n="@@cookiesIUnderstand"
				>
					I understand
				</button>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>

<style>
	@import url("https://fonts.googleapis.com/css?family=Acme&display=swap");
</style>
<style>
	@import url("https://fonts.googleapis.com/css?family=Montserrat:300&display=swap");
</style>
