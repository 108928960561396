import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-branch",
	templateUrl: "./branch.component.html",
	styleUrls: ["./branch.component.scss"],
})
export class BranchComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
