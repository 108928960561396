<div class="container-fluid">
	<div class="row justify-content-center">
		<div class="col-lg-6 col-md-6 col-sm-12 col-12">
			<div class="card">
				<div class="card-header">User details</div>

				<div class="card-body" *ngIf="userDetail">
					<div class="alert alert-success" *ngIf="saved">
						<span>User details was saved</span>
					</div>

					<app-blc-spinner [loading]="loading"></app-blc-spinner>

					<bl-user-detail-edit
						[userDetail]="userDetail"
						(patchValues)="onUserDetailSave($event)"
					></bl-user-detail-edit>
				</div>
			</div>
		</div>
	</div>
</div>
