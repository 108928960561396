<div class="container-fluid">
	<div class="row justify-content-center mt-2">
		<div class="col-auto text-secondary">
			<fa-icon [icon]="'book-open'" [size]="'3x'"></fa-icon>
		</div>
	</div>

	<div class="row justify-content-center mb-2">
		<div class="col-auto text-secondary">
			<h2 i18n="@@yourItemsHeadline">Your Items</h2>
		</div>
	</div>

	<div
		class="d-flex flex-column align-items-center mb-4"
		*ngIf="activeCustomerItems.length > 0 || wait"
	>
		<h4>Valgt skole</h4>

		<select
			(change)="onBranchSelect($event.target.value)"
			class="form-select p-1"
		>
			<option
				*ngFor="let branch of allBranches"
				[value]="branch.id"
				[selected]="branch.id === selectedBranch.id"
			>
				{{ branch.name }}
			</option>
		</select>
	</div>

	<div class="row" *ngIf="activeCustomerItems.length <= 0">
		<div class="col-12">
			<div class="alert alert-warning" i18n="@@noItems">
				You currently have no items
				<b><a routerLink="/i/select">click here to rent some</a></b>
			</div>
		</div>
	</div>

	<div class="container">
		<div class="row">
			<div
				class="col-12"
				*ngFor="let activeCustomerItem of activeCustomerItems"
			>
				<app-user-customer-item
					[customerItem]="activeCustomerItem"
				></app-user-customer-item>
			</div>
		</div>
	</div>

	<div class="container">
		<hr />
		<div class="row justify-content-center mb-5">
			<div class="col">
				<button
					class="btn btn-outline-secondary btn-block"
					i18n="@@showInactiveCustomerItemsButton"
					(click)="
						showInactiveCustomerItems = !showInactiveCustomerItems
					"
				>
					Show inactive customer items
				</button>
			</div>
		</div>
		<div class="row" *ngIf="showInactiveCustomerItems">
			<div
				class="col-12"
				*ngFor="let inactiveCustomerItem of inactiveCustomerItems"
			>
				<app-user-customer-item
					[customerItem]="inactiveCustomerItem"
				></app-user-customer-item>
			</div>
		</div>
	</div>
</div>

<div class="bl-goto-footer" *ngIf="cartActive()">
	<div class="row justify-content-center">
		<div class="col-sm-12 col-md-4">
			<button
				class="btn btn-success btn-block"
				routerLink="/cart"
				i18n="@@userItemGoToCart"
			>
				Go to cart
			</button>
		</div>
	</div>
</div>
