<div class="row">
	<div class="col-12">
		<h6 class="text-secondary" i18n="@@buybackTitle">
			List of items that we buy in. Be aware that this list can be changed
			at any time and may include errors.
		</h6>
	</div>
</div>
<div class="row">
	<div class="col-12">
		<div
			class="alert alert-secondary"
			i18n="@@infoBuybackNoItems"
			*ngIf="items.length <= 0"
		>
			No items in list. Head back later to view a updated list.
		</div>
	</div>
	<div class="col-12">
		<ul class="list-group">
			<li
				class="list-group-item justify-content-between d-flex"
				*ngFor="let item of items"
			>
				<div class="d-flex w-100 justify-content-between">
					<div>
						<h6>{{ item.title }}</h6>
						<small class="font-weight-lighter">{{
							item.info.isbn
						}}</small>
					</div>
				</div>
			</li>
		</ul>
	</div>
</div>
