<div class="container" *ngIf="branch">
	<div class="row justify-content-center">
		<div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
			<div class="row justify-content-center align-items-center">
				<div class="col-auto font-italic">
					<span
						class="bl-text-sm"
						i18n="the booklist for branch x @@itemSelectBooklistFor"
						>Booklist for</span
					>
				</div>
				<div class="col-auto">
					<button
						class="btn btn-block btn-light bl-text-sm"
						routerLink="/fastbuy/regions"
					>
						<span>{{ branch.name }}</span>
						<fa-icon [icon]="'caret-down'" class="ml-2"></fa-icon>
					</button>
				</div>
			</div>

			<div class="row justify-content-center">
				<div class="col-12">
					<app-item-display-category
						[branch]="branch"
						[selectedCategories]="selectedCategories"
						[autoAdd]="autoAdd"
						class="bl-container-padding"
					></app-item-display-category>
				</div>
			</div>
			<div class="col-auto" *ngIf="showPartlyPaymentInfo">
				<h2 i18n="@@cartPartlyPaymentInfoTitle">
					About partly payments
				</h2>
				<p>
					Du betaler restbeløpet på det oppgitte tidspunktet.
					Restbeløpet betales ved vår bokinnkjøpsstand på din skole på
					slutten av semesteret eller på nett. Mange privatister
					ønsker å selge bøkene sine på slutten av semesteret og
					Boklisten kjøper inn bøker fra privatister.
				</p>
				<p>
					Hvis du selger boken din til Boklisten vil vi vanligvis
					betale det samme som restbeløpet eller mer.
				</p>
			</div>
		</div>
	</div>
</div>

<div class="bl-goto-footer" *ngIf="cartSize > 0">
	<div
		*ngIf="!isUserDetailValid"
		class="alert alert-warning text-center nav-link"
	>
		<fa-icon [icon]="'exclamation-triangle'" class="mr-2"></fa-icon>
		Du må fylle inn detaljer om deg selv før du kan bestille.
		<a class="bl-link font-weight-bold" (click)="navigateToUserDetails()"
			>Trykk her for å gå til brukerinnstillinger.</a
		>
	</div>
	<div class="row justify-content-center">
		<div class="col-sm-12 col-md-4">
			<button
				class="btn btn-success btn-block"
				routerLink="/cart/checkout"
				[disabled]="!isUserDetailValid"
			>
				<fa-icon icon="flag-checkered" class="mr-2"></fa-icon>
				<span i18n="@@itemDisplayCategoryCheckout">Checkout</span>
			</button>
		</div>
	</div>
</div>
