<button
	class="btn text-white btn-block btn-sm"
	(click)="onAdd()"
	*ngIf="!isAdded()"
>
	<fa-icon icon="cart-plus"></fa-icon>
</button>
<button
	class="btn btn-block btn-sm text-right"
	(click)="onDelete()"
	*ngIf="isAdded()"
>
	<fa-icon class="text-danger" icon="times"></fa-icon>
</button>
