<div class="container-fluid">
	<div class="row justify-content-center mt-3">
		<div class="col-auto text-secondary">
			<fa-icon [icon]="'receipt'" [size]="'3x'"></fa-icon>
		</div>
	</div>

	<div class="row justify-content-center mt-2 mb-3">
		<h2
			class="col-auto text-secondary"
			i18n="order history title @@orderHistoryTitle"
		>
			Order history
		</h2>
	</div>

	<app-blc-spinner [loading]="loading"></app-blc-spinner>

	<div class="row" *ngIf="!showOrders()">
		<div class="col-12">
			<div
				class="alert alert-warning"
				i18n="no orders warning @@orderHistoryEmptyWarning"
			>
				You have no Orders yet
			</div>
		</div>
	</div>

	<div class="container">
		<app-order-info
			*ngFor="let order of orders.reverse()"
			[order]="order"
			[userDetail]="userDetail"
		></app-order-info>
	</div>
</div>
