<div class="container-fluid">
	<div class="row justify-content-center text-secondary mt-5">
		<div class="col-auto">
			<fa-icon [icon]="'sign-out-alt'" [size]="'3x'"></fa-icon>
		</div>
	</div>
	<div class="row justify-content-center mt-2">
		<div class="col-auto text-secondary">
			<h4 i18n="@@logoutYouHaveBeenLoggedOut">
				You have been logged out
			</h4>
		</div>
	</div>
</div>
