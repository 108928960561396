<div class="container-fluid" *ngIf="orderNotFoundError">
	<div class="alert alert-danger">
		<fa-icon icon="exclamation-triangle" class="mr-2"></fa-icon>
		<span i18n="@@cartConfirmNotFoundError"
			>Failed to confirm order. Could not find the order you are looking
			for</span
		>
	</div>
</div>
<div id="bl-dibs-wrapper"></div>
