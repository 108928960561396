<app-cart-summary></app-cart-summary>

<div class="row justify-content-center mt-2 mb-2" *ngIf="wait">
	<div class="col-auto text-secondary">
		<fa-icon [icon]="'circle-notch'" [spin]="true"></fa-icon>
		<span class="ml-2" i18n="@@cartPaymentContactingDibs"
			>contacting DIBS, please wait.</span
		>
	</div>
</div>

<app-cart-payment-dibs *ngIf="!wait"></app-cart-payment-dibs>
