<div class="row justify-content-center">
	<div class="col-auto" *ngIf="confirmed">
		<fa-icon [icon]="'check'" class="mr-2 text-success"></fa-icon>

		<span i18n="@@cartAgreementYouHaveAgreed"
			>You have agreed to our agreement</span
		>
	</div>
</div>

<app-info-agreement-conditions
	[collapsable]="true"
></app-info-agreement-conditions>

<app-cart-signature
	*ngIf="isSignatureByCustomerRequired"
	#signatureComponent
	(isValid)="onIsSignedChanged($event)"
	[customerName]="customerName"
	[signerName]="customerName"
	[isGuardian]="isUnderage"
></app-cart-signature>

<div class="row justify-content-center">
	<div class="col-12">
		<button
			class="btn btn-block btn-outline-success"
			[disabled]="!loaded || (isSignatureByCustomerRequired && !isSigned)"
			(click)="onConfirmClick()"
		>
			<span i18n="@@cartAgreementAgreeButton">Agree</span>
		</button>
	</div>
</div>
