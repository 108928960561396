<div class="row">
	<div class="col-12">
		<h2 i18n="@@cartPartlyPaymentInfoTitle">About partly payments</h2>
		<p>
			Du betaler restbeløpet på det oppgitte tidspunktet. Restbeløpet
			betales ved vår bokinnkjøpsstand på din skole på slutten av
			semesteret eller på nett. Mange privatister ønsker å selge bøkene
			sine på slutten av semesteret og Boklisten kjøper inn bøker fra
			privatister.
		</p>
		<p>
			Hvis du selger boken din til Boklisten vil vi vanligvis betale det
			samme som restbeløpet eller mer.
		</p>
	</div>
</div>
<div class="row">
	<div class="col-12">
		<button
			class="btn btn-outline-success btn-block"
			(click)="onConfirm()"
			i18n="@@cartPartlyPaymentInfoConfirmButton"
		>
			Confirm
		</button>
	</div>
</div>
