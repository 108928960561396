<nav
	class="navbar fixed-top bl-header d-flex align-items-center"
	[ngClass]="{ 'bl-dev-header': isDevEnvironment }"
>
	<div routerLink="/welcome">
		<img
			src="../../assets/img/boklisten_logo_v2_icon_white_lg.png"
			height="40"
			width="40"
		/>
	</div>
	<div routerLink="/welcome" class="mr-auto d-sm-inline d-none">
		<b class="bl-logo-text bl-link" i18n="@@headerCompanyName"
			>Boklisten.no</b
		>
	</div>
	<div
		class="text-white bl-link ml-auto bl-header-button d-md-inline d-sm-none d-none"
		routerLink="/info/general"
	>
		<span i18n="@@headerInfo"> Info </span>
	</div>

	<div
		class="text-white bl-link ml-5 bl-header-button d-md-inline d-sm-none d-none"
		(click)="onOrderItemsClick()"
	>
		<span i18n="@@headerOrderItems"> Order items</span>
	</div>

	<div
		class="text-white bl-link ml-auto ml-sm-5 bl-header-button bl-cart-button"
		routerLink="/cart"
		*ngIf="cartSize > 0"
	>
		<fa-icon [icon]="'shopping-cart'" class="mr-1"></fa-icon>
	</div>

	<div
		class="text-white bl-link ml-5 bl-header-button mr-3 bl-burger-button"
		(click)="onMenuClick()"
	>
		<fa-icon *ngIf="!showMenu" [icon]="'bars'" size="lg"> </fa-icon>
		<fa-icon *ngIf="showMenu" [icon]="'times'" size="lg"> </fa-icon>
	</div>
</nav>
<div class="bl-burger-menu position-fixed" *ngIf="showMenu">
	<div
		class="row justify-content-md-end justify-content-sm-center no-gutters"
	>
		<div class="col-lg-3 col-md-6 col-sm-12 bl-burger-menu-content">
			<app-user-menu
				[(showMenu)]="showMenu"
				(showMenuChange)="onInsideMenuClick()"
				(clickOutside)="outsideMenuClick($event)"
			></app-user-menu>
		</div>
	</div>
</div>
