<div class="row mb-2" *ngIf="item && customerItem">
	<div class="col-12">
		<div class="card">
			<div class="card-header bg-info">
				<div class="row justify-content-between text-white">
					<div class="col-auto" *ngIf="item">{{ item.title }}</div>
				</div>
			</div>
			<div
				class="card-body bg-danger text-white"
				*ngIf="notReturnedBeforeDeadline"
			>
				<div class="row">
					<div class="col-auto">
						<fa-icon
							[icon]="'exclamation-triangle'"
							class="mr-2"
						></fa-icon>
						<span i18n="@@userCustomerItemNotReturnedError"
							>This item has not been returned before the
							deadline, you will receive information about what to
							do next.</span
						>
					</div>
				</div>
			</div>
			<div
				class="card-body bg-secondary text-white"
				*ngIf="
					returned && !customerItem.buyout && !customerItem.buyback
				"
			>
				<div class="row">
					<div class="col-auto">
						<fa-icon [icon]="'check'" class="mr-2"></fa-icon>
						<span
							i18n="
								a happy message that tells the customer they
								have returned the item
								@@userCustomerItemReturnedMessage"
							>This item has been returned, thanks!</span
						>
					</div>
				</div>
			</div>

			<div
				class="card-body bg-success text-white"
				*ngIf="
					customerItem.match &&
					!customerItem.buyout &&
					!customerItem.buyback &&
					!returned
				"
			>
				<div class="row">
					<div class="col-auto">
						<fa-icon [icon]="'user-friends'" class="mr-2"></fa-icon>
						<span i18n="@@userCustomerItemMatchPendingMessage"
							>We will try to match this item for you</span
						>
					</div>
				</div>
			</div>
			<div
				class="card-body bg-secondary text-white"
				*ngIf="customerItem.buyout && !customerItem.buyback"
			>
				<div class="row">
					<div class="col-auto">
						<fa-icon [icon]="'check'" class="mr-2"></fa-icon>
						<span i18n="@@userCustomerItemBuyoutMessage"
							>You have bought this item</span
						>
					</div>
				</div>
			</div>
			<div
				class="card-body bg-secondary text-white"
				*ngIf="!customerItem.buyout && customerItem.buyback"
			>
				<div class="row">
					<div class="col-auto">
						<fa-icon [icon]="'check'" class="mr-2"></fa-icon>
						<span i18n="@@userCustomerItemBuybackMessage"
							>You have sold this item back to us</span
						>
					</div>
				</div>
			</div>

			<div class="card-body" *ngIf="customerItem">
				<div
					class="row justify-content-between"
					*ngIf="customerItem.blid"
				>
					<div class="col-auto text-secondary font-weight-bold">
						BL-ID
					</div>
					<div class="col-auto text-secondary">
						{{ customerItem.blid }}
					</div>
				</div>
				<div class="row justify-content-between">
					<div
						class="col-auto text-secondary font-weight-bold"
						i18n="
							customer item handed out at time
							@@customerItemHandedOut"
					>
						Handed out
					</div>
					<div class="col-auto text-secondary">
						{{ customerItem.handoutInfo.time | blcDate }}
					</div>
				</div>
				<div
					class="row justify-content-between"
					*ngIf="
						!returned && !customerItem.buyout && !customerItem.match
					"
				>
					<div
						class="col-auto text-secondary font-weight-bold"
						i18n="customer item deadline @@customerItemDeadline"
					>
						Deadline
					</div>
					<div class="col-auto text-secondary">
						<fa-icon [icon]="'clock'" class="mr-2"></fa-icon>
						{{ customerItem.deadline | blcDate }}
					</div>
				</div>
				<div
					class="row justify-content-between"
					*ngIf="
						customerItem.type == 'partly-payment' &&
						!customerItem.returned &&
						!customerItem.buyout
					"
				></div>
				<div
					class="row justify-content-between"
					*ngIf="
						customerItem.type == 'partly-payment' &&
						!customerItem.returned &&
						!customerItem.buyout &&
						!customerItem.match
					"
				>
					<div
						class="col-auto text-secondary font-weight-bold"
						i18n="@@customerItemAmountLeftToPay"
					>
						Amount left to pay
					</div>
					<div class="col-auto text-secondary">
						{{ customerItem.amountLeftToPay | blcPrice }}
					</div>
				</div>
				<div
					class="row justify-content-between"
					*ngIf="
						customerItem.type == 'partly-payment' &&
						!customerItem.returned &&
						!customerItem.buyout
					"
				></div>
				<div class="row justify-content-between" *ngIf="returned">
					<div
						class="col-auto text-secondary font-weight-bold"
						i18n="customer item deadline @@customerItemReturned"
					>
						Returned
					</div>
					<div
						class="col-auto text-secondary"
						*ngIf="customerItem.returnInfo"
					>
						{{ customerItem.returnInfo.time | blcDate }}
					</div>
				</div>
				<div class="row justify-content-between" *ngIf="returned">
					<div
						class="col-auto text-secondary font-weight-bold"
						i18n="customer item deadline @@customerItemReturned"
					>
						Returned to
					</div>
					<div
						class="col-auto text-secondary"
						*ngIf="customerItem.returnInfo"
					>
						{{
							customerItem.returnInfo.returnedToId
								| blcBranch
								| async
						}}
					</div>
				</div>
				<div
					class="row justify-content-between"
					*ngIf="
						!returned &&
						!customerItem.buyout &&
						customerItem.type !== 'partly-payment'
					"
				>
					<div
						class="col-auto text-secondary font-weight-bold"
						i18n="deliver customerItem to@@customerItemDeliverTo"
					>
						Deliver to
					</div>
					<div class="col-auto text-secondary bl-link-black">
						<span
							routerLink="{{
								'/b/info/' +
									customerItem.handoutInfo.handoutById
							}}"
						>
							{{
								customerItem.handoutInfo.handoutById
									| blcBranch
									| async
							}}
						</span>
					</div>
				</div>
			</div>
			<div
				class="card-body bg-warning"
				*ngIf="
					!correctBranch &&
					!notReturnedBeforeDeadline &&
					!returned &&
					!customerItem.buyout
				"
			>
				<div class="row">
					<div class="col-auto">
						<fa-icon
							[icon]="'exclamation-triangle'"
							class="mr-2"
						></fa-icon>
						<span
							>Denne boken er ikke fra din nåværende valgte skole.
							Du må velge
							<strong>{{ handoutBranch.name }}</strong> for å
							gjøre endringer for denne boka.</span
						>
					</div>
				</div>
			</div>
			<div
				class="card-footer"
				*ngIf="
					correctBranch &&
					!notReturnedBeforeDeadline &&
					!returned &&
					!customerItem.buyout
				"
			>
				<div class="row justify-content-end">
					<div class="col-sm-auto col-12">
						<div class="row">
							<div class="col-sm-auto col-12">
								<button
									class="btn btn-block"
									(click)="onExtendClick()"
									[ngClass]="{
										'btn-success': extend,
										'btn-outline-secondary': !extend
									}"
									[disabled]="!canExtend"
								>
									<fa-icon
										*ngIf="!extend"
										[icon]="'calendar-plus'"
										class="mr-2"
									></fa-icon>
									<fa-icon
										*ngIf="extend"
										[icon]="'check'"
										class="mr-2"
									></fa-icon>
									<span i18n="@@customerItemExtendButton"
										>Extend</span
									>
								</button>
							</div>
						</div>
						<div
							class="row justify-content-center mt-1"
							*ngIf="extend"
						>
							<div class="col-auto text-secondary">
								<span
									i18n="@@userCustomerItemExtendTo"
									class="mr-2"
									>extend to</span
								>
								<span class="font-weight-bold">
									{{
										customerItem
											| blcCustomerItemDate
												: "extend"
												: "semester"
											| blcDate
									}}
								</span>
							</div>
							<div
								class="col-auto text-secondary font-weight-bold"
							>
								{{
									customerItem
										| blcCustomerItemPrice: "extend"
										| async
										| blcPrice
								}}
							</div>
						</div>
					</div>
					<div class="col-sm-auto col-12 mt-sm-0 mt-2">
						<div class="row">
							<div class="col-sm-auto col-12">
								<button
									class="btn btn-block"
									(click)="onBuyoutClick()"
									[disabled]="!canBuyout"
									[ngClass]="{
										'btn-outline-secondary': !buyout,
										'btn-success': buyout
									}"
								>
									<fa-icon
										[icon]="'money-bill-wave'"
										*ngIf="!buyout"
										class="mr-2"
									></fa-icon>
									<fa-icon
										[icon]="'check'"
										*ngIf="buyout"
										class="mr-2"
									></fa-icon>
									<span i18n="@@customerItemBuyoutButton"
										>Buyout</span
									>
								</button>
							</div>
						</div>
						<div
							class="row justify-content-center mt-1"
							*ngIf="buyout"
						>
							<div class="col-auto text-secondary">
								{{
									customerItem
										| blcCustomerItemPrice: "buyout"
										| async
										| blcPrice
								}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
