<div class="row">
	<div class="col">
		<h3>{{ generalInfoTitle }}</h3>
	</div>
</div>

<div class="row mb-2">
	<div class="col">
		<p>{{ generalInfoDesc }}</p>
	</div>
</div>

<div class="row mt-2" *ngFor="let generalInfo of generalInfos">
	<div class="col-12">
		<h5>{{ generalInfo.title }}</h5>
	</div>
	<div class="col-12">
		<p *ngFor="let textBlock of generalInfo.textBlocks">{{ textBlock }}</p>
	</div>
</div>
