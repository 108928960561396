import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-info-agreement-privacy",
	templateUrl: "./info-agreement-privacy.component.html",
	styleUrls: ["./info-agreement-privacy.component.scss"],
})
export class InfoAgreementPrivacyComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
