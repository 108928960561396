<div class="container">
	<div class="row mb-3">
		<div class="col text-center">
			<h3 i18n="@@fastbuySelectCoursesWhatCourses">
				What courses do you take?
			</h3>

			<hr />
		</div>
	</div>
	<div class="row justify-content-center" *ngIf="wait">
		<div class="col-auto">
			<fa-icon
				[icon]="'circle-notch'"
				[spin]="true"
				[size]="'2x'"
			></fa-icon>
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-md-6 col-12">
			<button
				class="mb-2 btn btn-outline-info btn-block"
				[ngClass]="{ 'btn-info text-white': isSelected(course.name) }"
				(click)="select(course.name)"
				*ngFor="let course of courses"
			>
				<div class="d-flex justify-content-between align-items-center">
					<span>{{ course.name }}</span>
					<span *ngIf="isSelected(course.name)"
						><fa-icon icon="check"></fa-icon
					></span>
				</div>
			</button>
		</div>
	</div>
</div>

<div class="bl-goto-footer" *ngIf="haveSelected()">
	<div class="row justify-content-center">
		<div class="col-sm-12 col-md-4">
			<button
				class="btn btn-success btn-block"
				(click)="goToItemList()"
				i18n="@@fastbuySelectCoursesGoToItemList"
			>
				Generate your booklist
			</button>
		</div>
	</div>
</div>
