<div class="row">
	<div class="col">
		<h4>{{ aboutUsTitle }}</h4>
	</div>
</div>

<div class="row">
	<div class="col">
		<p>{{ aboutUsDesc }}</p>
	</div>
</div>

<div class="row" *ngFor="let aboutUsText of aboutUsTexts">
	<div class="col-12">
		<h5>{{ aboutUsText.title }}</h5>
	</div>
	<div class="col-12">
		<p *ngFor="let textBlock of aboutUsText.textBlocks">{{ textBlock }}</p>
	</div>
</div>

<div class="row">
	<div class="col-12">
		<h5>{{ aboutUsList.title }}</h5>
	</div>
	<div class="col-12">
		<ul>
			<li *ngFor="let listItem of aboutUsList.listItems">
				{{ listItem }}
			</li>
		</ul>
	</div>
</div>
