<div class="container">
	<div class="row mb-3">
		<div class="col text-center">
			<h3 i18n="@@fastbuySelectRegionWhereTitle">
				Where do you go to school?
			</h3>

			<hr />
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-md-6 col-12">
			<button
				class="mb-2 btn btn-block btn-info"
				*ngFor="let region of regions"
				[routerLink]="link"
				[queryParams]="region.params"
			>
				<div class="d-flex justify-content-between align-items-center">
					<span>{{ region.name }}</span>
					<span><fa-icon icon="chevron-right"></fa-icon></span>
				</div>
			</button>
			<h6 style="margin-top: 1rem">
				Jeg går ikke på noen skole (bøker til overs)
			</h6>
			<button
				class="mb-2 btn btn-block btn-info"
				[routerLink]="'/fastbuy/courses'"
				[queryParams]="{ branch: '63c5715d38bbec00484aa540' }"
			>
				<div class="d-flex justify-content-between align-items-center">
					<span>Fri privatist</span>
					<span><fa-icon icon="chevron-right"></fa-icon></span>
				</div>
			</button>
		</div>
	</div>
</div>
