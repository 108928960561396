<div class="row bg-white">
	<div class="col-12">
		<div class="row">
			<div class="col-12">
				<span class="text-secondary bl-text-sm mb-2"
					>Sist oppdatert: 19.01.20</span
				>
				<h1>Personvernerklæring</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<h4>Grunnlag for personvernerklæringen</h4>
				<p>
					Grunnlaget er for denne personvernerklæringen er å informere
					om hvordan Boklisten.no samler inn, behandler og bruker
					personopplysninger.
				</p>

				<h4>Behandlingsansvarlig</h4>
				<p>
					Boklisten.no AS, herunder daglig leder, er
					behandlingsansvarlig for alle personopplysninger som blir
					gitt fra kundene, og vi behandler personopplysningene i
					samsvar med «Lov om behandling av personopplysninger» av
					20.07.2018.
				</p>
				<p>
					Behandlingsansvarlig har ansvar for alle personopplysninger
					som blir gitt til oss, enten ved at du oppgir disse direkte
					ved opprettelse av bruker og gjennom bestilling av bøker,
					eller som vi samler inn basert på din bruk av våre
					nettsider.
				</p>
				<p>Kontaktinformasjonen vår finner du nederst på nettsiden.</p>

				<h4>Hva slags informasjon samler vi inn?</h4>
				<p>
					Boklisten.no samler inn informasjon som skal hjelpe oss med
					å tilby deg lån og salg av brukte og nye lærebøker. For å
					kunne gjøre dette samler vi inn en rekke personopplysninger
					om våre kunder.
				</p>
				<p>
					Opplysninger som du oppgir for å låne eller kjøpe bøker av
					oss, kan være personsensitive.
				</p>
				<p>
					Disse opplysningene henter vi fra deg før du kan låne eller
					kjøpe bøker:
				</p>
				<ul>
					<li>Din e-postadresse</li>
					<li>Ditt fulle navn</li>
					<li>Ditt telefonnummer</li>
					<li>Din adresse</li>
					<li>Din fødselsdag</li>
					<li>
						Dersom du er under 18 år henter vi inn navn,
						e-postadresse og telefonnummer fra en av dine foresatte
					</li>
					<li>Skolen du går på, eller skal låne bøker fra</li>
				</ul>
				<p>
					Disse opplysningene kan vi hente basert på hvordan du bruker
					nettsiden vår:
				</p>
				<ul>
					<li>Hvilke bøker du har lånt</li>
					<li>Tidspunkt du har lagt inn bestilling</li>
					<li>Betalingsinformasjon</li>
					<li>
						Tidspunkt for når du henter bøker samt hvor dette var
					</li>
					<li>
						Tidspunkt for når du leverer bøker samt hvor dette var
					</li>
					<li>Tidspunkt for sist innlogging</li>
				</ul>

				<h4>Informasjonskapsler (cookies)</h4>
				<p>
					Informasjonskapsler er små datafiler som lagres lokalt på
					din harddisk for at vår nettside skal yte bedre. Dette kan
					inkludere å holde deg logget inn på nettsiden mens du er på
					den, eller for å beholde informasjon du har lagt inn mens du
					navigerer deg rundt på siden.
				</p>
				<p>
					Under finner du en oversikt over hvilke informasjonskapsler
					vi bruker:
				</p>
				<ul>
					<li>
						Bl-access-token
						<ul>
							<li>
								Brukes til å lagre informasjon vi trenger for å
								opprettholde en innloggingssesjon
							</li>
							<li>
								informasjonen lagret her er: E-post og din
								bruker-id
							</li>
							<li>
								Utløpstidspunkt: 1-10 minutter etter
								informasjonen ble lagret
							</li>
						</ul>
					</li>
					<li>
						Bl-refresh-token
						<ul>
							<li>
								Brukes til å be om nye nøkler for adgang til
								vårt backend-system.
							</li>
							<li>
								Informasjonen lagret her er: E-post og din
								bruker-id.
							</li>
							<li>
								Utløpstidspunkt: 6-18 måneder etter
								informasjonen ble lagret
							</li>
						</ul>
					</li>
					<li>
						Bl-shopping-cart
						<ul>
							<li>
								Brukes til å huske handlekurven din slik at man
								for eksempel kan gå ut og inn på siden uten at
								denne forsvinner.
							</li>
							<li>
								Informasjonen lagret her er: objekter i
								handlekurven
							</li>
						</ul>
					</li>
					<li>
						Bl-branch-id
						<ul>
							<li>
								Brukes for å huske hvilket filial/skole du har
								valgt slik at man for eksempel kan gå ut og inn
								på siden uten at dette forsvinner.
							</li>
							<li>
								Informasjonen lagret her er: id til filial/skole
							</li>
						</ul>
					</li>
					<li>
						Bl-redirect
						<ul>
							<li>
								Brukes til å huske på hvilken side man skal
								komme tilbake til etter man for eksempel har
								logget inn
							</li>
							<li>Informasjonen lagret her er: url</li>
						</ul>
					</li>
				</ul>
				<p>
					Informasjonen er lagret under "Local Storage" i din
					nettleser.
				</p>

				<h4>Hvorfor og hvordan samler vi inn informasjon?</h4>
				<p>
					Boklisten.no samler inn informasjon for å kunne tilby våre
					kunder lån og salg av brukte og nye lærebøker på en god,
					trygg og effektiv måte. Informasjonen vi samler inn skal
					aldri brukes til andre formål enn å forbedre våre tjenester,
					og for å sikre at både du og vi oppfyller avtaler overfor
					hverandre.
				</p>
				<p>
					Under følger en liste over grunnlag for å samle inn
					informasjon:
				</p>
				<ul>
					<li>Behandling med ditt samtykke</li>
					<li>Behandling for å oppfylle kontraktsforpliktelser</li>
					<li>Behandling for å oppfylle lovlige krav</li>
					<li>Behandling knyttet til våre legitime interesser</li>
				</ul>

				<h4>Deling av informasjon med eksterne</h4>
				<p>
					Dine personopplysninger kan overføres til våre
					samarbeidspartnere. Disse vil behandle personopplysninger på
					vegne av Boklisten.no for å kunne tilby tjenestene som
					opplysningene opprinnelig er gitt for. Det er gjennomført
					sikkerhetstiltak dersom vi i noen tilfeller må overføre dine
					personopplysninger til en samarbeidspartner i utlandet.
					Dette vil kun skje unntaksvis, og etter en grundig vurdering
					av datasikkerheten.
				</p>
				<p>
					Vi vil aldri selge eller utveksle informasjon til andre
					tredjeparter for markedsføringsformål som ikke har med
					Boklisten.no å gjøre, og vil ikke kommersialisere dine
					personopplysninger.
				</p>
				<p>
					Hvis du skal gi eller motta en bok direkte fra en annen
					kunde/låner vil denne kunden/låneren få ditt navn og
					telefonnummer og du vil likeledes få denne kundens navn og
					telefonnummer.
				</p>
				<p>
					Under finner du en liste med eksempler på hvilke
					samarbeidspartnere Boklisten.no kan dele personopplysninger
					med:
				</p>
				<ul>
					<li>
						Våre leverandører av IT-tjenester, med
						oppbevaringsformål.
					</li>
					<li>
						Våre samarbeidspartnere for utsendelse av faktura,
						inndrivelse av krav og inkassosaker.
					</li>
					<li>
						Våre samarbeidspartnere for betalingstjenester, herunder
						forhåndsbestillinger på nett og betaling på stand.
					</li>
					<li>Våre samarbeidspartnere for utsendelse av varer.</li>
					<li>
						Våre samarbeidspartnere for utsendelse av automatiske
						e-post og SMS, herunder ordrebekreftelser og informasjon
						om inn- og utlevering, og påminnelser om dette.
					</li>
					<li>
						Våre samarbeidspartnere for inn- og utlogging av våre
						applikasjoner.
					</li>
				</ul>

				<h4>Datasikkerhet</h4>
				<p>
					Vi gjør vårt beste for å sikre at dine personopplysninger
					ikke kommer på avveie. I Boklisten.no er alle
					personopplysninger oppbevart på en sikker måte. Vi har
					rutiner for rapportering til de relevante myndigheter dersom
					det blir oppdaget datainnbrudd eller andre hendelser som vil
					påvirke sikkerheten til dine personopplysninger.
				</p>
				<p>
					Kun ansatte med spesiell klarering vil kunne ha tilgang til
					våre databaser.
				</p>

				<h4>Hvor lenge beholder vi dine data?</h4>
				<p>
					Vi beholder dine data så lenge det er nødvendig for å kunne
					tilby deg tjenestene eller produktene du har fra oss. Dette
					inkluderer oppfyllelse av kontraktsbetingelser som følge av
					bestilling av bøker, og andre lovmessige eller
					regnskapsmessige krav. Dine sensitive opplysninger vil
					uansett bli slettet senest 1 år etter at du sist har vært
					innlogget eller hatt bøker hos oss. Anonymiserte data som
					ikke er sensitive kan beholdes for analyseformål. Dersom du
					ønsker å slette din brukerprofil og data før det gjøres
					automatisk, kan dette gjøres ved å sende en mail til oss.
					Sletting skjer kun under forutsetning av at det ikke er noe
					juridisk eller økonomisk mellomværende mellom Boklisten.no
					og deg som kunde. Informasjonskapsler (cookies) som
					Boklisten.no har lagret på din maskin vil bli slettet etter
					en gitt tid. Les mer om informasjonskapsler i eget avsnitt
					om dette.
				</p>

				<h4>Dine rettigheter</h4>
				<p>
					Etter personopplysningsloven har alle som spør rett på
					informasjon om behandling av personopplysninger i
					Boklisten.no. Denne retten kan inkludere å:
				</p>
				<ul>
					<li>Få tilgang til dine personlige data</li>
					<li>Forespørre om endring av dine personlige data</li>
					<li>Kreve sletting av dine personlige data</li>
					<li>Motsette deg bruk av dine personlige data</li>
					<li>
						Be om begrensninger i bruken av dine personlige data
					</li>
					<li>
						Trekke tilbake samtykke til bruk av dine personlige data
					</li>
				</ul>
				<p>
					Dersom du ønsker å benytte deg av noen av disse rettigheten,
					ber vi deg ta kontakt med oss på e-post.
				</p>
			</div>
		</div>
	</div>
</div>
